.botWrapper {
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    height: 450px;
    width: 50%;
    min-width: 600px;
}

.studentCarousel {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 40%;
    height: 100%;
    justify-content: space-evenly;
}

.currentStudent {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: aliceblue;
    padding: 40px;
    border-radius: 10px;
    gap: 10px;
}

.headShot {
    width: 180px;
    height: 180px;
}

.chatWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 90%;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.chatDisplay {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow: auto;
    background-color: #f4fffa;
    box-shadow: -1px 1px 20px black;
    border-radius: 5px 5px 0 0;
    font-family: monospace;
    border: 0.8px solid black;
}

.chatInput {
    width: 100%;
    border-radius: 0px 0px 0px 5px;
    border: none;
    outline: 1px solid black;
    background-color: #f4fffa;
    padding: 5px 0px 0px 10px;
}

.carouselButtons {
    align-items: center;
    background-color: var(--color-deepskyblue);
    border-radius: var(--br-3xs);
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    height: 40px;
    width: 50%;
    justify-content: center;
    padding: var(--padding-12xs) var(--padding-lg);
    text-align: center;
}

.carouselNavigationContainer{
    margin: auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.studentInfo {
    border: 1px solid black;
    width: 180px;
    height: 20px;
    margin: 0;
}

.typingIndicator {
    color: aliceblue;
}

.submitButton {
    width: 50px;
    height: 100%;
    background-color: var(--color-cornflowerblue);
    border: none;
    outline: 1px solid black;
    font-family: monospace;
    border-radius: 0px 0px 5px 0px;
    padding: 2px 6px;
}

.userMessage {
    background-color: #b3b7b5;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    align-self: flex-end;
    width: auto;
    max-width: 80%;
    height: auto;
}

.botMessage {
    background-color: var(--color-cornflowerblue);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    align-self: flex-start;
    width: auto;
    height: auto;
    max-width: 80%;
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.1);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }

  .noStudentMessage{
    width: auto;
    height: auto;
    margin: 0;
    padding: 20px;
    font-family: var(--font-montserrat);
    font-size: 16pt;
    color: white;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
  }
  