.dashboardContainer {
  font-family: var(--font-montserrat);
  background-color: #002f35;
  min-height: 100vh;
  padding: 40px; 
  display: flex;
  justify-content: flex-start; 
  align-items: flex-start; 
}

.gridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px; 
}

.profileCard {
  background-color: white;
  color: black;
  width: 250px; 
  height: auto;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  word-wrap: break-word;
}

.profileImageContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profileImage {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.profileDetails b {
  display: block;
  margin-bottom: 10px;
}

.ageGender {
  display: block; 
  margin-bottom: 10px;
}

.age, .gender {
  display: block;
}

.school, .major {
  display: block; 
}

.unsyncButton {
  background-color: #00A2FF;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
}

.unsyncButton:hover {
  background-color: #ff4a4a;
}

@media (max-width: 768px) {
  .gridContainer {
    flex-direction: column;
  }

  .profileCard {
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  }
}
