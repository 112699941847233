/* Gender Styles */
.genderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 75%;
  height: 100%;
  border-radius: 10px;
  background-color: #D9D9D9;
  overflow: hidden;
}

.genderIcon {
  height: 100%;
  width: 45px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
}

.femaleSelected {
  background-color: lightcoral;
}

.maleSelected {
  background-color: lightskyblue;
}

.otherSelected {
  border: 2px solid rgb(208, 135, 250);
}

.otherGender {
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
}

.otherGenderInput {
  max-width: 110px;
  height: 100%;
  font-size: 20px;
  border-radius: 10px;
  border: 0;
  background-color: #fff;
  margin: 0;
  text-align: center;
  z-index: 1;
  font-family: Montserrat, sans-serif;
}

/* picture Styles */

.picture {
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 78px 41px;
  z-index: 1;
}

.picture:hover {
  background-color: #E6E6E6;
}

.headshot,
.resume,
.locationInput,
.ethnicityInput {
  font-size: 20px;
  font-family: Montserrat, sans-serif;
  color: #000;
  text-align: center;
  margin: auto;
}

/* Resume Styles */
.resumeContainer {
  cursor: pointer;
  border: 0;
  padding: 24.5px 48px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
}

.resumeContainer:hover {
  background-color: #E6E6E6;
}

/* Location and Ethnicity Styles */
.locationInput,
.ethnicityInput {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  color: #000;
  box-sizing: border-box;
  border: 0;
  z-index: 1;
  font-family: Montserrat, sans-serif;
}

/* Education and Clubs Section Styles */
.educationSection,
.clubSection {
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat, sans-serif;
}

.educationSection {
  height: 180px;
  overflow-y: scroll;
}

.clubSection {
  height: 123px;
  overflow-y: scroll;
}

/* Age Styles */
.ageInput {
  width: 100%;
  height: 100%;
  font-size: 20px;
  border-radius: 10px;
  border: 0;
  box-sizing: border-box;
  background-color: #fff;
  margin: 0;
  text-align: center;
  z-index: 1;
  font-family: Montserrat, sans-serif;
}

.ageContainer {
  height: 100%;
  width: 25%;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

/* Group and Wrapper Styles */
.leftGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 180px;
}

.rightGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 205px;
  margin: 0;
}

.bottomGroup {
  display: flex;
  flex-direction: row;
  height: 40px;
  gap: 10px;
}

.sectionsWrapper {
  max-width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
}

.createProfileWrapper {
  width: 505px;
  margin: auto;
  background-color: #01313F;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.saveButton {
  cursor: pointer;
  border: 0;
  padding: 6px 20px 5px 34px;
  background-color: #02A9F7;
  width: 505px;
  border-radius: 10px;
  margin-top: 10px;
  z-index: 1;
  font-family: Montserrat, sans-serif;
}

.saveButton:hover {
  background-color: #008FDE;
}

/* Text Styles */
.createProfile {
  position: relative;
  font-size: 32px;
  font-family: Montserrat, sans-serif;
  color: #000;
  text-align: left;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 0;
  margin-top: 20px;
  gap: 10px;
  font-family: Montserrat, sans-serif;
}

.dropdownItem {
  text-decoration: none;
  font-size: 16px;
  list-style: none;
  padding: 5px;
  font-family: Montserrat, sans-serif;
}

.dropdownItem:hover {
  border: 1px solid black;
  border-radius: 5px;
  background-color: #fff;
}

.selectedClub {
  list-style-type: none;
}

.educationTitle {
  font-size: 20px;
  font-family: Montserrat, sans-serif;
  font-weight: lighter;
  color: #000;
  text-align: center;
  margin: 0;
}

.searchBar {
  width: auto;
  height: 20px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid gray;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  color: #000;
  text-align: left;
  overflow: scroll;
  margin: 0;
}

.clubsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.addClubButton {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: var(--color-deepskyblue);
  border: 0;
  border-radius: 5px;
  color: #fff;
  font-family: Montserrat, sans-serif;
}

.addClubButton:hover {
  background-color: var(--color-cornflowerblue);
}

.removeClubButton {
  margin-right: 10px;
  background-color: var(--color-deepskyblue);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
}

.removeClubButton:hover {
  background-color: var(--color-cornflowerblue);
}

.clubsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 10px;
  text-align: left;
}

/* Media Queries */
@media screen and (max-width: 750px) {
  .createProfile {
    font-size: 26px;
  }
  .clubSection {
    margin-top: 3px;
    height: 123px;
    overflow-y: scroll;
  }
  .bottomGroup {
    margin-top: 0.5px;
  }
  .otherGender {
    margin-bottom: 7px;
  }
}

@media screen and (max-width: 450px) {
  .headshot,
  .locationInput,
  .ethnicityInput,
  .resume {
    font-size: 16px;
  }

  .ageInput,
  .otherGender {
    font-size: 16px;
  }

  .createProfile {
    font-size: 19px;
  }
}

/* Location Dropdown */
.locationInput {
  width: 100%;
  height: 40px;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}

.locationDropdownContainer {
  position: relative;
  width: 100%;
}

.locationDropdownList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.locationDropdownItem {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: Montserrat, sans-serif;
}

.locationDropdownItem:hover {
  background-color: #f0f0f0;
}

.ethnicityDropdownContainer {
  position: relative;
  width: 100%;
  overflow: visible; /* Ensure the dropdown is not clipped by its parent */
}

.ethnicityInput {
  width: 100%;
  height: 40px;
  font-size: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}

.ethnicityDropdownList {
  top: auto;
  left: auto;
  max-height: 100px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Ensure it's on top of other elements */
}

.ethnicityDropdownItem {
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: Montserrat, sans-serif;
}

.ethnicityDropdownItem:hover {
  background-color: #f0f0f0;
}

.uploaded {
  background-color: #d4edda; /* Light green background */
  border-color: #c3e6cb; /* Green border */
}

/* Hide content and show prompt for portrait mode */
@media screen and (orientation: portrait) {
  .createProfileWrapper {
    display: none; /* Hide the form */
  }


  .rotateMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: lightblue;
    font-family: Montserrat, sans-serif;
    height: 400px;
    font-size: 24px;
    color: black;
    padding: 15px;
    border-radius: 10px;
  }
}

/* Show content in landscape mode */
@media screen and (orientation: landscape) {
  .rotateMessage {
    display: none; /* Hide the prompt */
  }

  .createProfileWrapper {
    display: flex; /* Show the form */
  }
}

