.studentsP4Child {
  width: 1440px;
  height: 900px;
  position: relative;
  background-color: var(--color-darkslategray);
  display: none;
  max-width: 100%;
}

.startNetworking {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}
.startNetworkingWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-3xl) 0 var(--padding-xl);
}
.createYourProfile {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}
.createYourProfileConnectWWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-30xl) 0 var(--padding-28xl);
  font-size: var(--font-size-16xl);
  color: var(--color-white);
}
.frameParent,
.studentsP4Inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.frameParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-8xl);
}
.studentsP4Inner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-43xl);
  color: var(--color-deepskyblue);
  font-family: var(--font-montserrat);
}
.createProfile {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  min-width: 74px;
}

.nextProjectButton {
  margin-top: 10px;
  padding: 8px 12px;
  font-size: 14px;
  background-color: var(--color-deepskyblue);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 180px;
}

/*Skip*/
.done {
  cursor: pointer;
  border-radius: var(--br-3xs);
  background-color: var(--color-deepskyblue);
  color: black;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-2xs) var(--padding-lg);
  z-index: 1;
  height: 41px;
  font-size: 25px;
  text-align: center;
}
/*Sync*/
.createProfile1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  min-width: 80px;
  height: 37.5px;
  font-size: 25px;
  text-align: center;
  line-height: 38px;
}
/*sync*/
.done1,
.doneParent,
.pictureParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
/*sync*/
.done1 {
  cursor: pointer;
  border-radius: var(--br-3xs);
  background-color: var(--color-deepskyblue);
  color: black;
  overflow: hidden;
  padding: 0 14px var(--padding-12xs);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 41px;
  width: 240px;
  font-size: 25px;

  z-index: 1;
}

.doneParent,
.pictureParent {
  gap: var(--gap-mini);
}
.pictureParent {
  flex-direction: column;
  max-width: 235px;
}
.commonGround {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  height: 75px;
}
.commonGround,
.featureComingSoon {
  position: relative;
}
.comingSoonLabels {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-wrap: wrap;
}
.picture {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-11xl) 97px;
  gap: 49px;
  z-index: 1;
}
.featureComingSoonWrapper,
.picture,
.picture1 {
  /*height: 101px;*/
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.featureComingSoonWrapper {
  max-height: 150px;
  overflow-y: auto; 
  padding: 10px;
  font-size: 14px; 
  text-align: center;
  word-wrap: break-word; 
}

.picture1 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  z-index: 1;
  padding: 20px;
  align-items: center;
  height: auto;
  justify-content: center;
  /* width: 100%;
  max-width: 400px;  
  background-color: var(--color-white);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
 */
}
.frameGroup,
.pictureGroup {
  justify-content: flex-start;
  max-width: 100%;
}
.pictureGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-mini);
  min-width: 211px;
  text-align: center;
  font-size: var(--font-size-11xl);
}
.frameGroup {
  width: 580px;
  flex-direction: row;
  gap: var(--gap-xl);
}
.frameGroup,
.frameSection,
.studentsP4 {
  display: flex;
  align-items: flex-start;
}
.frameSection {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}
.studentsP4 {
  width: 100%;
  position: relative;
  background-color: var(--color-darkslategray);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-41xl) var(--padding-2xl);
  box-sizing: border-box;
  gap: var(--gap-65xl-5);
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 1275px) {
  .createYourProfileConnectWWrapper {
    padding-left: var(--padding-4xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1100px) {
  .navigationLinksWrapper {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .startNetworking {
    font-size: var(--font-size-31xl);
  }
  .createYourProfile {
    font-size: var(--font-size-9xl);
  }
  .createProfile,
  .createProfile1 {
    font-size: var(--font-size-7xl);
  }
  .pictureParent {
    flex: 1;
  }
  .commonGround {
    font-size: var(--font-size-5xl);
  }
  .frameGroup {
    flex-wrap: wrap;
  }
  .studentsP4 {
    gap: var(--gap-23xl);
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .startNetworking {
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
  }
  .createYourProfile {
    font-size: 14px;
    text-align: center;
  }
  .createProfile,
  .createProfile1 {
    font-size: var(--font-size-lg);
  }
  .pictureParent {
    max-width: 235px;
    margin: 0 auto;
  }
  .picture1,
  .picture {
    display: none;
  }
  .studentsP4 {
    gap: var(--gap-2xl);
  }
}