.profilePicture {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
  width: 100%
}
.picture1 {
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.profileImage {
  width: 210px;
  height: 210px;
  object-fit: cover;
  border-radius: 10px;
  padding: 5px;
}
.name,
.namebox {
  position: relative;
}
.namebox {
  height: 25px;
  width: 220px;
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
  display: none;
}
.name {
  display: inline-block;
  min-width: 50px;
  z-index: 1;
}
.personalInfo {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) var(--padding-9xs) var(--padding-11xs);
  font-size: var(--font-size-base);
}
.age,
.agebox {
  position: relative;
}
.agebox {
  height: 20px;
  width: 80px;
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
  display: none;
}
.age {
  display: inline-block;
  min-width: 29px;
  z-index: 1;
}
.genderbox,
.userGender {
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
}
.userGender {
  width: 80px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) var(--padding-9xs) var(--padding-11xs);
  box-sizing: border-box;
}
.genderbox {
  height: 20px;
  width: 130px;
  position: relative;
  display: none;
}
.gender {
  position: relative;
  display: inline-block;
  min-width: 53px;
  z-index: 1;
}
.personalInfo1,
.userGender1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.userGender1 {
  flex: 1;
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
  padding: var(--padding-12xs) var(--padding-9xs) var(--padding-11xs);
}
.personalInfo1 {
  align-self: stretch;
  gap: var(--gap-3xs);
}
.schoolbox {
  height: 20px;
  width: 220px;
  position: relative;
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
  display: none;
}
.school {
  position: relative;
  display: inline-block;
  min-width: 48px;
  z-index: 1;
}
.personalInfo2 {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-gainsboro);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) var(--padding-9xs) var(--padding-11xs);
}
.major {
  position: relative;
  display: inline-block;
  min-width: 40px;
  z-index: 1;
}
.picture,
.profileDetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
  font-size: var(--font-size-sm);
  width: 220px;

}

.picture {
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  overflow: hidden;
  padding: var(--padding-5xs) var(--padding-6xs) var(--padding-5xs)
    var(--padding-5xs);
  gap: var(--gap-4xs);
  z-index: 1;
  text-align: left;
  font-size: var(--font-size-11xl);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}
@media screen and (max-width: 750px) {
  .profilePicture {
    font-size: var(--font-size-5xl);
  }
}
@media screen and (max-width: 450px) {
  .profilePicture {
    font-size: var(--font-size-lg);
  }
}

