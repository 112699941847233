@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-base: 16px;
  --font-size-13xl: 32px;
  --font-size-16xl: 35px;
  --font-size-2xl: 21px;
  --font-size-9xl: 28px;
  --font-size-43xl: 62px;
  --font-size-18xl: 37px;
  --font-size-31xl: 50px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-7xl: 26px;
  --font-size-11xl: 30px;
  --font-size-sm: 14px;

  /* Colors */
  --color-darkslategray: #01313f;
  --color-white: #fff;
  --color-deepskyblue: #02a9f7;
  --color-cornflowerblue: #008fde;
  --color-black: #000;
  --color-gainsboro: #d9d9d9;

  /* Gaps */
  --gap-65xl-5: 84.5px;
  --gap-2xl: 21px;
  --gap-23xl: 42px;
  --gap-xl: 20px;
  --gap-21xl: 40px;
  --gap-3xs: 10px;
  --gap-4xs: 9px;
  --gap-8xl: 27px;
  --gap-mini: 15px;
  --gap-18xl: 37px;
  --gap-lg: 18px;
  --gap-8xs: 5px;

  /* Paddings */
  --padding-21xl: 40px;
  --padding-41xl: 60px;
  --padding-2xl: 21px;
  --padding-11xl: 30px;
  --padding-3xs: 10px;
  --padding-11xs: 2px;
  --padding-10xs: 3px;
  --padding-60xl: 79px;
  --padding-59xl: 78px;
  --padding-xl: 20px;
  --padding-2xs: 11px;
  --padding-7xs: 6px;
  --padding-8xs: 5px;
  --padding-12xs: 1px;
  --padding-9xs: 4px;
  --padding-30xl: 49px;
  --padding-28xl: 47px;
  --padding-4xl: 23px;
  --padding-5xl: 24px;
  --padding-3xl: 22px;
  --padding-15xl: 34px;
  --padding-6xs: 7px;
  --padding-22xl: 41px;
  --padding-4xs: 9px;
  --padding-9xl: 28px;
  --padding-10xl: 29px;
  --padding-95xl: 114px;
  --padding-7xl: 26px;
  --padding-lgi: 19px;
  --padding-lg: 18px;
  --padding-8xl: 27px;
  --padding-mid: 17px;
  --padding-5xs: 8px;
  --padding-72xl: 91px;
  --padding-73xl: 92px;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-21xl: 40px;
  --br-8xs: 5px;
}

/* Navbar Styling */
.navbar {
  background-color: var(--color-darkslategray);  /* Using your defined dark slate gray color */
  padding: var(--padding-xl);  /* Consistent padding */
}

.nav-list {
  list-style-type: none;
  display: flex;
  gap: var(--gap-xl);  /* Consistent gap between list items */
  margin: 0;
  padding: 0;
}

.nav-item a {
  color: var(--color-white);  /* White text color */
  text-decoration: none;
  font-weight: bold;
  font-family: var(--font-montserrat);  /* Use the Montserrat font */
  font-size: var(--font-size-lg);  /* Set a consistent font size */
}

.nav-item a:hover {
  text-decoration: underline;
}
