.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-xl) var(--padding-4xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--color-deepskyblue);
  font-family: var(--font-montserrat);
  max-width: 100%;
  background-color: #e9e9e9;
  position: relative;
}

.freshfroshParent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
  flex: 1;
  padding-left: var(--padding-4xl);
}

.freshfrosh {
  text-decoration: none;
  font-weight: 700;
  color: inherit;
  white-space: nowrap;
  z-index: 1;
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 625px;
}

.navList {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: var(--gap-xl);
  max-width: 100%;
}

.navItem {
  padding: 0 var(--padding-xs);
}

.navLink {
  text-decoration: none;
  font-weight: 500;
  color: var(--color-black);
  font-family: var(--font-montserrat);
}

.navLink:hover {
  text-decoration: underline;
}

.graphics {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: var(--padding-4xl);
}

.vectorIcon {
  width: 24px;
  height: 24px;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

/* Styles for Mobile */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: var(--color-black);
  border-radius: 2px;
}

@media (max-width: 897px) {
  .content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0; /* Aligns the dropdown to the left edge */
    right: 0; /* Aligns the dropdown to the right edge */
    width: 100vw; /* Ensures the dropdown takes up the full viewport width */
    background-color: #E9E9E9;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-lg);
    z-index: 100;
    box-sizing: border-box; /* Ensures padding is included in the width */
  }
  .open {
    display: flex;
  }
  .navList {
    flex-direction: column;
    gap: var(--gap-md);
  }
  .navItem {
    padding: var(--padding-md) 0;
  }
  .hamburger {
    display: flex;
  }
  .graphics {
    display: none;
  }
}

/* Mobile Screens */
@media (max-width: 430px) {
  .content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #E9E9E9;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--padding-lg);
    z-index: 100;
  }
  .open {
    display: flex;
  }
  .navList {
    flex-direction: column;
    gap: var(--gap-md);
  }
  .navItem {
    padding: var(--padding-md) 0;
  }
  .hamburger {
    display: flex;
  }
  .graphics {
    display: none;
  }
}