/* General styling for the registration form */
.registrationForm {
    width: 300px;
    max-width: 400px;
    margin: 0 auto;
    background-color: #01303f; /* Background color for the form */
    border-radius: 20px; /* Rounded corners */
    padding: 20px; /* Padding inside the form */
  }
  
  .inputWrapper {
    margin-bottom: 15px; /* Space between inputs */
  }
  
  .input {
    width: 100%;
    padding: 12px; /* Increased padding for larger input area */
    border-radius: 10px; /* Rounded input fields */
    border: 1px solid #ccc;
    font-size: 16px; /* Increased font size */
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif; /* Use Montserrat font */
  }
  
  /* Button styles */
  .submissionButton {
    text-align: center;
  }
  
  .done {
    width: 100%;
    padding: 10px; /* Larger padding for the button */
    border-radius: 10px; /* Rounded button */
    border: none;
    background-color: #02a9f7;
    color: #000000; /* Black font color */
    font-size: 24px; /* Increased font size */
    cursor: pointer;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif; /* Apply Montserrat font */
  }
  
  .done:hover {
    background-color: #0197d6;
  }
  
  /* Error and success messages */
  .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  
  .success {
    color: green;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 600px) {
    .registrationForm {
      padding: 15px;
      border-radius: 15px; /* Adjust rounded corners on small screens */
    }
  
    .input {
      padding: 10px;
      font-size: 16px;
      border-radius: 8px; /* Adjust input rounding for small screens */
    }
  
    .done {
      font-size: 20px; /* Adjust button font size for small screens */
      padding: 8px; /* Adjust button padding for small screens */
      border-radius: 8px; /* Adjust button rounding for small screens */
    }
  }
  