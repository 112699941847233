/* General styling for the sign-up and login forms */
.signUpForm {
    width: 300px;
    max-width: 400px;
    margin: 0 auto;
    background-color: #01303f; /* Background color for the form */
    border-radius: 20px; /* More rounded corners */
    padding: 20px; /* Add padding to give space inside the form */
  }
  
  .emailWrapper, .passwordWrapper, .nameWrapper {
    margin-bottom: 15px;
  }
  
  .input {
    width: 100%;
    padding: 12px; /* Increase padding for a bigger input area */
    border-radius: 10px; /* More rounded input fields */
    border: 1px solid #ccc;
    font-size: 16px; /* Increase font size */
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif; /* Apply Montserrat font to inputs */
  }
  
  /* Button styles */
  .submissionButton {
    text-align: center;
  }
  
  .done {
    width: 100%;
    padding: 10px; /* Increase the padding for a bigger button */
    border-radius: 10px; /* More rounded button */
    border: none;
    background-color: #02a9f7;
    color: #000000; /* Change font color to black */
    font-size: 24px; /* Increase the font size */
    cursor: pointer;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif; /* Apply Montserrat font to buttons */
  }
  
  .done:hover {
    background-color: #0197d6;
  }
  
  /* Error and success messages */
  .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  
  .message {
    color: green;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  
  /* Link and text styling for switching between forms and forgot password */
  .switchTextWrapper {
    text-align: center;
    margin-top: 15px;
  }
  
  .switchText {
    color: #ffffff;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif; /* Apply Montserrat font to switch text */
  }
  
  .switchLink {
    color: #02a9f7;
    cursor: pointer;
    text-decoration: underline;
  }
  
  /* Additional styling for layout and responsive design */
  @media (max-width: 600px) {
    .signUpFormContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh; /* Full height to center vertically */
      padding: 15px;
    }
  
    .signUpForm {
      padding: 15px;
      border-radius: 15px; /* Slightly adjust the rounded corners for small screens */
    }
  
    .input {
      padding: 10px;
      font-size: 16px;
      border-radius: 8px; /* Adjust input field rounding on small screens */
    }
  
    .done {
      font-size: 20x; /* Adjust button font size for small screens */
      padding: 8px; /* Adjust button padding for small screens */
      border-radius: 8px; /* Adjust button rounding on small screens */
    }
  
    .switchText {
      font-size: 14px;
    }
  }
  