.studentsP1Child {
  width: 100%;
  height: auto; /* Allow height to adapt */
  position: relative;
  background-color: var(--color-darkslategray);
  display: none;
  max-width: 100%;
}

.startNetworking {
  margin: 0;
  position: relative;
  font-size: 50px; 
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
  color: #02a9f7;
}

.networkingSlogan {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-3xl) 0 var(--padding-xl);
}

.createYourProfile {
  margin: 0;
  position: relative;
  font-size: 25px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
  text-align: center;
  width: 100%
}

.navigation {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-30xl) 0 var(--padding-28xl);
  font-size: var(--font-size-16xl);
  color: var(--color-white);
}

.contactInfo,
.mainContent {
  justify-content: flex-start;
  max-width: 100%;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-8xl);
}

.contactInfo {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  text-align: left;
  font-size: var(--font-size-43xl);
  color: var (--color-deepskyblue);
  font-family: var(--font-montserrat);
}

.contactInfo,
.signUpFormWrapper,
.studentsP1 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}

.signUpFormWrapper {
  align-self: stretch;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  padding: 0; /* Remove any padding above and below the form */
  margin: 0; /* Remove any margin around the form */
  max-width: 100%;
}

.loginSection {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Ensure the section takes up the full width */
  padding: var(--padding-5xl); /* Add padding around the section */
  box-sizing: border-box;
}

.haveAnAccountContainer {
  position: relative;
  font-size: 16px;
  color: #fff;
  font-family: Montserrat;
  text-align: center; /* Center text within the container */
  z-index: 1;
  margin-bottom: -20px;
}

.logIn {
  background: none;
  border: none;
  color: #02a9f7;
  cursor: pointer;
  font-size: inherit;  /* Matches the font size of the surrounding text */
  font-family: inherit; /* Matches the font family of the surrounding text */
  text-decoration: underline;
  padding: 0;  /* Removes padding */
  margin: 0;   /* Removes margin */
  font-weight: bold;
}

.studentsP1 {
  width: 100%;
  min-height: 100vh; /* Ensure it covers at least the full viewport height */
  position: relative;
  background-color: var(--color-darkslategray);
  overflow: auto; /* Allow content to be scrollable */
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-41xl) 0; /* Adjust bottom padding */
  gap: var(--gap-65xl-5);
  line-height: normal;
  letter-spacing: normal;
}

.footerp1 {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: var(--color-darkslategray);
  color: var(--color-white);
  text-align: center;
  margin-top: auto; /* Push footer to the bottom */
}

@media screen and (max-width: 1275px) {
  .navigation {
    padding-left: var(--padding-4xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .startNetworking {
    font-size: var(--font-size-31xl);
    text-align: center;
  }
  .createYourProfile {
    font-size: var(--font-size-9xl);
  }
  .studentsP1 {
    gap: var(--gap-23xl);
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
    padding-top: var(--padding-15xl); /* Add padding to the top */
    padding-bottom: var(--padding-15xl); /* Add padding to the bottom */
  }
}

@media screen and (max-width: 450px) {
  .startNetworking {
    font-size: var(--font-size-18xl);
    text-align: center;
    white-space: nowrap;
    
  }
  .createYourProfile {
    font-size: 18px;
  }
  .studentsP1 {
    gap: var(--gap-2xl);
    padding-left: var(--padding-8xl); /* Add padding to sides for mobile */
    padding-right: var(--padding-8xl); /* Add padding to sides for mobile */
    padding-top: var(--padding-10xl); /* Add padding to the top */
    padding-bottom: var(--padding-10xl); /* Add padding to the bottom */
  }
  .haveAnAccountContainer {
    font-size: 16px;
    margin-bottom: -20px;
  }
  .loginSection {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
