.studentsP3Child {
  width: 1440px;
  height: 900px;
  position: relative;
  background-color: var(--color-darkslategray);
  display: none;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.startNetworkingWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.startNetworking {
  margin: 0;
  position: relative;
  font-size: 50px;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}

.createYourProfileConnectWWrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Full width */
  text-align: center; /* Ensure text inside the child is centered */
  flex-direction: column; /* Stack items vertically, just in case */

}

.createYourProfile {
  color: white;
  margin: 0;
  font-size: 25px;
  font-weight: 400;
  font-family: inherit;
  z-index: 1;
}

.frameParent,
.studentsP3Inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.frameParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-8xl);
}

.studentsP3Inner {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-43xl);
  color: var(--color-deepskyblue);
  font-family: var(--font-montserrat);
}

.createProfile {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  min-width: 74px;
}

.nextProjectButton {
  margin-top: 10px;
  padding: 8px 12px;
  font-size: 14px;
  background-color: var(--color-deepskyblue);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 180px;
}

/*Get Started*/
.done {
  border-radius: var(--br-3xs);
  background-color: var(--color-deepskyblue);
  color: black;
  align-items: center;
  justify-content: center;
  padding: var(--padding-12xs) var(--padding-lg);
  height: 40px;
  width: 235px;
  z-index: 1;
  font-size: 24px;
  cursor: pointer;
}

.createProfile1 {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  min-width: 81px;
}

.done1,
.doneParent,
.pictureParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.done1 {
  border-radius: var(--br-3xs);
  background-color: var(--color-deepskyblue);
  overflow: hidden;
  padding: 0 14px var(--padding-12xs);
  z-index: 1;
}

.doneParent,
.pictureParent {
  gap: var(--gap-mini);
}

.pictureParent {
  width: 235px;
  flex-direction: column;
  min-width: 235px;
}

.commonGround {
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;
}

.commonGround,
.featureComingSoon {
  position: relative;
}

.comingSoonLabels {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 59px;
  font-size: var(--font-size-sm);
}

.picture {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-3xs) var(--padding-11xl) 97px;
  gap: 49px;
  z-index: 1;
}

.featureComingSoonWrapper,
.picture,
.picture1 {
  /*height: 101px;*/
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.featureComingSoonWrapper {
  max-height: 150px; /* Adjust height to prevent overflow */
  overflow-y: auto; /* Enable scrolling if content overflows */
  padding: 10px;
  font-size: 14px; /* Adjust font size for better readability */
  text-align: center;
  word-wrap: break-word; /* Prevent text from overflowing */
}

.picture1 {
  align-self: stretch;
  border-radius: var(--br-3xs);
  background-color: var(--color-white);
  overflow: hidden;
  flex-direction: column;
  z-index: 1;
  padding: 20px;
  align-items: center;
  height: auto;
  justify-content: center;
  /* width: 100%;
  max-width: 400px;  
  background-color: var(--color-white);
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
 */
}

.frameGroup,
.pictureGroup {
  justify-content: flex-start;
  max-width: 100%;
}

.pictureGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-mini);
  min-width: 211px;
  text-align: center;
  font-size: var(--font-size-11xl);
}

.frameGroup {
  width: 580px;
  flex-direction: row;
  gap: var(--gap-xl);
}

.frameGroup,
.frameSection,
.studentsP3 {
  display: flex;
  align-items: flex-start;
}

.frameSection {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-13xl);
  color: var(--color-black);
  font-family: var(--font-montserrat);
}

.studentsP3 {
  width: 100%;
  position: relative;
  background-color: var(--color-darkslategray);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-41xl) var(--padding-2xl);
  box-sizing: border-box;
  gap: var(--gap-65xl-5);
  line-height: normal;
  letter-spacing: normal;
}


@media screen and (max-width: 750px) {
  .startNetworking {
    font-size: var(--font-size-31xl);
    text-align: center;
  }
  
  .createYourProfile {
    font-size: var(--font-size-9xl);
  }
  
  .createProfile,
  .createProfile1 {
    font-size: var(--font-size-7xl);
  }
  
  .pictureParent {
    flex: 1;
  }
  
  .commonGround {
    font-size: var(--font-size-5xl);
  }
  
  .frameGroup {
    flex-wrap: wrap;
  }
  
  .studentsP3 {
    gap: var(--gap-23xl);
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .startNetworking {
    font-size: var(--font-size-31xl);
  }
  
  .createYourProfile {
    font-size: var(--font-size-9xl);
  }
  
  .createProfile,
  .createProfile1 {
    font-size: var(--font-size-7xl);
  }
  
  .pictureParent {
    max-width: 235px;
    margin: 0 auto;
  }
  
  .commonGround {
    font-size: var(--font-size-5xl);
  }
  
  .frameGroup {
    flex-wrap: wrap;
  }
  
  .studentsP3 {
    gap: var(--gap-23xl);
    padding-left: var(--padding-11xl);
    padding-right: var(--padding-11xl);
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .startNetworking {
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
  }

  .createYourProfile {
    font-size: 15px;
    text-align: center;
  }

  .done {
    font-size: 15px;
  }
  
  .createProfile,
  .createProfile1 {
    font-size: 20px;
    align-content: center;
  }

  .pictureParent {
    max-width: 235px;
    margin: 0 auto;
    
  }

  .studentsP3 {
    gap: var(--gap-2xl);
  }

  .picture1,
  .picture {
    display: none;
  }

}
