.contact {
  background-color: var(--color-darkslategray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  margin: 0;
}

.header {
  text-align: center;
  margin-bottom: 10px; /* Reduced space between navbar and heading */
}

.getInTouch {
  color: var(--color-deepskyblue);
  margin: 0;
  margin-top: 21px;
  font-size: 48px;
  font-weight: 700;
  font-family: var(--font-montserrat);
}

.notifyUs {
  color: #ffffff;
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-montserrat);
  padding-top: 5px; /* Slightly reduced padding */
  padding-bottom: 30px; /* Added space after subheading */
}

.mainContent {
  background-color: #d9d9d9;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  max-height: 380px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.buttonContainerOne,
.buttonContainerTwo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.buttonContainerTwo {
  margin-bottom: 20px;
  gap: 10px; /* Space between buttons in the second row */
}

.first,
.second {
  width: 48%; /* Ensure two buttons fit on one line */
  padding: 10px;
  border-radius: 8px;
  background-color: white;
  color: black;
  cursor: pointer;
  border: 2px solid transparent;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  font-family: var(--font-montserrat);
}

.selected {
  background-color: var(--color-deepskyblue);
  color: white;
  border-color: var(--color-deepskyblue);
}

.inputField,
.inputMessage {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 15px;
  font-size: 16px;
  font-family: var(--font-montserrat);
}

.inputMessage {
  height: 100px;
  resize: vertical;
}

.submit {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: var(--color-deepskyblue);
  color: black;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: var(--font-montserrat);
  margin-top: 5px;
}

.submit:hover {
  background-color: #0197d6;
}

.error {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.footer {
  width: 90%;
  padding-top: 20px;
  padding-bottom: 5px;
  background-color: var(--color-darkslategray);
  color: var(--color-white);
  text-align: center;
  margin-top: auto; /* Push footer to the bottom */
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .mainContent {
    width: 100%; /* Reduced width to make the form narrower on mobile */
    max-width: 325px; /* Ensure the form doesn't get too wide */
    padding: 12px;
  }

  .getInTouch {
    font-size: 40px; /* Reduced font size for heading on mobile */
  }

  .notifyUs {
    margin-top: auto;
    font-size: 16px; /* Reduced font size for subheading on mobile */
  }

  .buttonContainerOne,
  .buttonContainerTwo {
    flex-wrap: nowrap; /* Keep the buttons in one row */
    justify-content: space-between;
    gap: 10px; /* Space between buttons */
  }

  .first,
  .second {
    width: 50%; /* Two buttons in the first row */
  }

  .buttonContainerTwo {
    flex-wrap: nowrap; /* Ensure the buttons remain in one row */
    justify-content: space-between;
    width: 100%;
  }

  .second {
    width: 35%; /* Adjust width for three buttons in one row on mobile */
  }
}
