html, body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

/* Footer styles */
.freshfroshLlcAll {
  text-align: center;
  font-weight: 500;
}

.copyright {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.privacyPolicy {
  display: inline-block;
  min-width: 125px;
}

.contactUs,
.privacyPolicy,
.termsOfService,
.signOut {
  position: relative;
  text-decoration: none;
  font-weight: 500;
  color: white;
}

.contactUs:hover,
.privacyPolicy:hover,
.termsOfService:hover {
  text-decoration: underline;
}

.signOut:hover {
  color: red
}

.contactUs {
  display: inline-block;
  min-width: 101px;
}

.footerLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px; /* Adjusted gap for more space between links */
  max-width: 100%;
  flex-wrap: wrap;
}

.riinstagramFillIcon {
  height: 22px;
  width: 22px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  transform: translateY(2px);
}

.footer,
.footerContent,
.mainMenu {
  align-self: stretch;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  justify-content: center;
}

.mainMenu {
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  gap: 20px;
}

.footer,
.footerContent {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.footerContent {
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: white;
  font-family: Montserrat, sans-serif;
}

.footer {
  z-index: 1;
  width: 100%;
  margin-top: auto; /* Ensures footer sticks to bottom */
  padding-bottom: 0; /* Remove extra space below */
}

/* Adjustments for medium screens */
@media screen and (max-width: 1275px) {
  .mainMenu {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Adjustments for smaller screens */
@media screen and (max-width: 750px) {
  .footerLinks {
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
}

/* Adjustments for very small screens */
@media screen and (max-width: 450px) {
  .copyright {
    justify-content: center;
    text-align: center;
    gap: 10px;
  }

  .footerContent {
    padding: 6px;
  }
}
