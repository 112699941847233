.fAS {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  z-index: 1;
}

.advantages,
.benefits,
.values {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.values {
  flex: 1;
  overflow-x: auto;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.advantages,
.benefits {
  align-self: stretch;
  justify-content: flex-start;
}

.benefits {
  flex-direction: row;
  padding: 0 var(--padding-3xl) 0 var(--padding-lgi);
  box-sizing: border-box;
}

.advantages {
  flex-direction: column;
  gap: var(--gap-18xl);
  text-align: left;
  font-size: var(--font-size-43xl);
  color: var(--color-white);
  font-family: var(--font-montserrat);
}

@media screen and (max-width: 1300px) {
  .fAS {
    font-size: var(--font-size-31xl);
  }
  
}

@media screen and (max-width: 1100px) {
  .fAS {
    font-size: var(--font-size-16xl);
  }
  
}

@media screen and (max-width: 820px) {
  .fAS {
    font-size: var(--font-size-31xl);
  }
  .advantages {
    gap: var(--gap-lg);
  }
  .values {
    flex-direction: column; /* Change to vertical stacking */
    align-items: center;    /* Center the items */
    justify-content: center; /* Center items vertically */
  }
}

@media screen and (max-width: 450px) {
  .fAS {
    font-size: var(--font-size-18xl);
  }
  .values {
    gap: var(--gap-md); /* Reduce the gap between items */
  }
}
