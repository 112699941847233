.adminContainer {
  padding: 20px;
  text-align: center;
}

.passwordContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.passwordInput {
  padding: 10px;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.passwordSubmit {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggleContainer {
  margin-bottom: 20px;
}

.toggleSwitch {
  margin-left: 10px;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
}

.adminTable {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.adminTable th, .adminTable td {
  padding: 10px;
  border: 1px solid #ddd;
}

.adminTable th {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.headerButton {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.headerButton:hover {
  background-color: #0056b3;
}

.activeFilter {
  background-color: #0056b3;
}

.missingFieldsRow {
  background-color: #f8d7da;
}

.inputField {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.saveButton, .editButton {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.saveButton:hover, .editButton:hover {
  background-color: #218838;
}

.link {
  color: #007bff;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
